import * as React from 'react'

interface Result {
    value: boolean;
    onToggle: () => void;
    onTrue: () => void;
    onFalse: () => void;
}

export const useBoolean = (initialValue: boolean): Result => {

  const [value, setValue] = React.useState<boolean>(initialValue)

  const onToggle = React.useCallback(() => setValue((prev) => !prev), [])
  const onFalse = React.useCallback(() => setValue(false), [])
  const onTrue = React.useCallback(() => setValue(true), [])

  const returnValue = React.useMemo(() => {
    return { value, onFalse, onTrue, onToggle }
  }, [value, onFalse, onTrue, onToggle])

  return returnValue
}
import * as React from 'react'
import { startStyleSync, checkStyleSync } from "api/inventoryService";
import { useBoolean } from 'hooks/useBoolean';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

const CHECK_INTERVAL = 1000 // next check api call after this time
const CHECK_TIMEOUT = 1000*10 // time to periodic check api call

const useStyleSync = () => {

  const control = useBoolean(false)
  const queryClient = useQueryClient()

  React.useEffect(() => {
    let intervalId: any = null
    let timeoutId: any = null
    let previous: AbortController|null = null
    let current: AbortController|null = null

    async function callback() {
      if(previous) previous.abort()
      current = new AbortController()
      previous = current
      const status = await checkStyleSync({ signal: current.signal }).catch((err) => {
        if(err.name !== "CanceledError"){
          control.onFalse()
          toast.error("Failed to sync style")
        }
      })
  
      if(status !== 'INACTIVE'){
        toast.success("Style synced successfully")
        clearInterval(intervalId)
        control.onFalse()
        queryClient.invalidateQueries({ queryKey: ["style-prices"] })
        return
      }
    }
    
    if(control.value){
      startStyleSync()
      .then(() => {
        intervalId = setInterval(callback, CHECK_INTERVAL)
        timeoutId = setTimeout(() => {
          control.onFalse()
          toast.error("Style sync timeout")
        }, CHECK_TIMEOUT)
      })
      .catch(() => {
        control.onFalse()
        toast.error("Failed to sync style")
      })
    }

    return () => {
      if(intervalId) clearInterval(intervalId)
      if(timeoutId) clearTimeout(timeoutId)
    }
  }, [control.value])

  return { isSyncing: control.value, startSyncing: control.onTrue }
  
}

export default useStyleSync
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchAllDozen, addDozen, updateDozen, updateStylePrice, fetchAllStylePrices, deleteStylePrice, fetchAllAMStyles } from "api/inventoryService";

const getStylePriceData = async () => {
    const data = await fetchAllStylePrices()
    const resultObj = {} as Record<string, number>
    if(!data) return resultObj
    for(let el of data){
        resultObj[`price-${el.styleId}-${el.dozenId}`] = Number(Number(el.price).toFixed(2))
    }
    return resultObj
}


export const useAMStyles = () => {
    return useQuery({
        queryKey: ["am-styles"],
        queryFn: fetchAllAMStyles
    })
};

export const useStylePriceUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateStylePrice,
        onMutate: (payload) => {
            const { styleId, dozenId, price } = payload
            const key = `price-${styleId}-${dozenId}`
            queryClient.cancelQueries({ queryKey: ["style-prices"] })
            // Snapshot the previous value
            const previousValues = queryClient.getQueryData(["style-prices"]) as Record<string, string>
            // Optimistically update to the new value
            queryClient.setQueryData(["style-prices"], (values: any) => ({ ...values, [key]: price }))
            // Return a context object with the target key and it's old value
            return { key, value: (previousValues?.[key] ?? '') } as any
        },
        // use the context returned from onMutate to roll back
        onError: (err, payload, context) => {
            queryClient.setQueryData(["style-prices"], (values: any) => ({ ...values, [context.key]: context.value }))
        },
        // Always refetch after error or success:
        onSettled: () => {
            // queryClient.invalidateQueries({ queryKey: ["style-prices"] })
        },
    });
};


export const useStylePriceDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteStylePrice,
        onMutate: (payload) => {
            const { styleId, dozenId } = payload
            let value = ''
            const key = `price-${styleId}-${dozenId}`
            queryClient.cancelQueries({ queryKey: ["style-prices"] })
     
            // Optimistically update to the new value
            queryClient.setQueryData(["style-prices"], (values: any) => {
                value = values[key] ?? ''
                delete values[key]
                return { ...values }
            })
            // Return a context object with the deleted key and value
            return { key, value } as any
        },
        // use the context returned from onMutate to roll back
        onError: (err, payload, context) => {
            queryClient.setQueryData(["style-prices"], (values: any) => ({ ...values, [context.key]: context.value }))
        },
        // Always refetch after error or success:
        onSettled: () => {
            // queryClient.invalidateQueries({ queryKey: ["style-prices"] })
        },
    });
};

  export const useStylePriceData = () => {
    return useQuery({
        queryKey: ["style-prices"],
        queryFn: getStylePriceData
    })
  };


  export const useDozens = () => {
    return useQuery({
        queryKey: ["dozens"],
        queryFn: fetchAllDozen as () => Promise<{ id: number; value: string; }[]>
    })
  };

  export const useDozenAdd = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: addDozen,
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["dozens"] })
        },
    });
};

export const useDozenUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateDozen,
        // // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["dozens"] })
        },
    });
};

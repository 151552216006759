import clsx from "clsx";
import * as React from "react";

import { toast } from "react-toastify";
import { useBoolean } from "hooks/useBoolean";
import { useAMStyles, useDozenAdd, useDozenUpdate, useDozens, useStylePriceData, useStylePriceDelete, useStylePriceUpdate } from "react-query/style-pricing";
import { useQueryClient } from "@tanstack/react-query";
import { clone } from "utils/helperFunctions";
import useStyleSync from "./useStyleSync";
interface Dozen { id: string|null; value: string; key: number; }
interface IProp {}

const StylesPricing: React.FC<IProp> = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { data: dozens } = useDozens()
  const [term, setTerm] = React.useState<string>("");

  const dozenEditable = useBoolean(false)
  const priceEditable = useBoolean(false)

  const { data: styles } = useAMStyles()
  const { data } = useStylePriceData()
  const { mutate: updateStylePrice, isPending: isUpdatingPrice } = useStylePriceUpdate()  
  const { mutate: deleteStylePrice, isPending: isDeletingPrice } = useStylePriceDelete()
  const { mutate: addDozen, isPending: isAddingDozen } = useDozenAdd()
  const { mutate: updateDozen, isPending: isUpdatingDozen } = useDozenUpdate()
  const { isSyncing, startSyncing } = useStyleSync()

  const isSaving = React.useMemo(() => {
    return isAddingDozen || isUpdatingDozen || isUpdatingPrice || isDeletingPrice
  }, [isAddingDozen, isUpdatingDozen, isUpdatingPrice, isDeletingPrice])


  const styleVisibilityObj = React.useMemo(() => {
    if (!term) return styles;
    const visibilityObj: any = {}
    for(let style of styles){
      if(!term){
        visibilityObj[style.id] = true
        continue
      }
      visibilityObj[style.id] = style.styleNumber.toLowerCase().includes(term.toLowerCase())
    }
    return visibilityObj
  }, [styles, term]);

  const addDozenNewColumn = () => {
    queryClient.setQueryData(["dozens"], (values?: Dozen[]) => {
      return [...(values ?? []), { id: null, value: ''}]
    })
  }

  const handleDozenChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): any => {
    const { id, index } = e.target.dataset
    const value = e.target.value.replace(/[^0-9]/g, '')
    if(index){
      queryClient.cancelQueries({ queryKey: ["dozens"] })
      queryClient.setQueryData(["dozens"], (old: any) => {
        const data = clone(old)
        data[index].value = value
        return data
      })
    }
    if(!value.length) return
    if(!id){
      addDozen({ value })
      return
    }
    updateDozen({ id, value })
  }, [queryClient])

  const handlePriceChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): any => {    
    const { styleId, dozenId } = e.target.dataset
    
    if(!Number(styleId)) return

    if(!Number(dozenId)){
      toast.error('Please enter dozen value first')
      return
    }
    const formatted = e.target.value.replace(/[^0-9\.]/g, '').replace(/^(\.)/, '0.')
    const price = parseFloat(formatted)
    
    if(formatted === String(price) && price > 0){
      // console.log("Updating = ", e.target.name, " => ", price)
      updateStylePrice({ styleId, dozenId, price })
    }else if(formatted === ""){
      // console.log("deleting = ", e.target.name, e.target.value)
      deleteStylePrice({ styleId, dozenId })
    }else{
      // console.log("Changing = ", e.target.name, " => ", e.target.value)
      queryClient.setQueryData(["style-prices"], (values: any) => ({ ...values, [e.target.name]: formatted }))
    }
  }, [queryClient])

  return (
    <>
      <div className="page-bottom-space" style={{ width: "991px" }}>
        <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
          <h4 className="mb-0 heading4-bold">Styles Pricing</h4>
          <span style={{ opacity: '0.7' }} >{isSaving && 'Saving...'}</span>
          <div className="">
            <button
              className="btn btn-danger me-2"
              disabled={isSyncing}
              onClick={startSyncing}
            >
              <i
                className={clsx("fas fa-sync me-2", { "fa-spin": isSyncing })}
              ></i>
              Sync Styles
            </button>
            <button
              className="btn btn-danger me-2"
              onClick={addDozenNewColumn}
              disabled={!dozenEditable.value}
            >
              <i className="fa fa-plus me-2"></i>Add Dozen
            </button>
            <button
              className="btn btn-danger me-2"
              onClick={dozenEditable.onToggle}
              style={{ minWidth: "65px" }}
              disabled={priceEditable.value === true || isSaving}
              
            >
              {dozenEditable.value ? (
                <>
                  <i className="fa-solid fa-stop me-2"></i> Stop Dozen Control
                </>
              ) : (
                <>
                  <i className="fa-solid fa-gamepad me-2"></i>Control Dozen
                </>
              )}
            </button>
            <button
              className="btn btn-danger"
              onClick={priceEditable.onToggle}
              style={{ minWidth: "65px" }}
              disabled={dozenEditable.value === true || isSaving}
            >
              {priceEditable.value ? (
                <>
                  <i className="fa-solid fa-stop me-2"></i>Stop Price Editing
                </>
              ) : (
                <>
                  <i className="fa fa-pencil me-2"></i>Edit Prices
                </>
              )}
            </button>
          </div>
        </div>
          <div className="overflow-auto" style={{ maxHeight: "600px" }}>
            <table className="table custom-new-table">
              <thead>
                <tr>
                  <th>Styles</th>
                  <th
                   colSpan={dozens?.length}
                   >Dozen</th>
                </tr>
                <tr>
                  <th>
                    <div className="search-input">
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                      ></i>
                      <input
                        className="form-control"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                      />
                    </div>
                  </th>
                  {dozens?.map((el, i) => (
                    <th key={i}>
                        <input
                          className="form-control"
                          value={el.value}
                          data-id={el.id}
                          data-index={i}
                          onChange={handleDozenChange}
                          disabled={!dozenEditable.value}
                        />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {styles?.map((style: any) => (
                    <tr key={style.id} className={clsx({ 'd-none': !styleVisibilityObj[style.id] })}>
                      <th style={{ minWidth: "150px" }}>{style.styleNumber}</th>
                      {dozens?.map((dozen, i) => {
                        const name = `price-${style.styleId}-${dozen.id}` 
                        return (
                          <td key={i}>
                            <input
                              className="form-control"
                              name={name}
                              value={data?.[name] || ""}
                              data-style-id={style.styleId}
                              data-dozen-id={dozen.id}
                              onChange={handlePriceChange}
                              disabled={!priceEditable.value}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
      </div>
    </>
  );
};

export default StylesPricing;
